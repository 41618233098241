/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.p-toolbar.iam-toolbar {
  padding: 0.5rem 1rem;
}

.invoice-blank {
  background-image: url(./assets/images/viewinvoice-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180%;
  height: 100%;
}

.captcha {
  border: 2px solid #ced4da;
  border-radius: 6px;
  height: 40px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.w-full > .p-inputtext {
  width: 100%;
}

.overview-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview-box.overview-box-blue {
  background-image: url(../public/images/dashboard/effect-4.svg);
}

.overview-box.overview-box-cyan {
  background-image: url(../public/images/dashboard/effect-1.svg);
}

.overview-box.overview-box-orange {
  background-image: url(../public/images/dashboard/effect-2.svg);
}

.overview-box.overview-box-purple {
  background-image: url(../public/images/dashboard/effect-3.svg);
}

.tthai-1 {
  color: #008b00;
  background-color: #ccffcc;
}

.tthai-2, .tthai-3 {
  color: #0000ff;
  background-color: #cfe2f3;
}

.tthai-4, .tthai-5 {
  color: #ff9900;
  background-color: #fff2cc;
}

.tthai-6 {
  color: #ff0000;
  background-color: #fce5cd;
}

.alert-error {
  color: white;
  background-color: red;
  border-color: #f5c6cb;
}

.alert-danger {
  color: black;
}

.View {
  .p-disabled, .p-component:disabled {
    opacity: 1;
  }
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 215px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  cursor: pointer;

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }

  .google-icon {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    left: 0;
  }

  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }

  &:active {
    background: $button-active-blue;
  }
}

.datatable-border-top .p-datatable-thead > tr > th {
  border-top: 1px solid #e9ecef;
}

.layout-topbar-menu .house-holds {
  color: var(--primary-color);
  cursor: pointer;

  > p:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
  }
}

.table-actions {
  > button {
    position: relative;

    .p-badge {
      position: absolute;
      right: 0px;
      top: 1px;
    }
  }
}

.p-tabview-h-full {
  height: 100%;

  .p-tabview-panels {
    height: 95%;

    .p-tabview-panel {
      height: 100%;
    }
  }
}

.p-menu.app-menu-20, .p-menu.app-menu-20 .p-menu-list, .p-menubar .app-menu-20 .p-submenu-list {
  width: 20rem;
}

.p-menu.app-menu-14, .p-menu.app-menu-14 .p-menu-list, .p-menubar .app-menu-14 .p-submenu-list {
  width: 14rem;
}

.layout-main-container {
  padding: 65px 0.5rem 0 1.5rem
}

.layout-wrapper.layout-static.layout-static-inactive .layout-main-container {
  margin-left: 0;
  padding-left: 0.5rem;
}

.layout-menu ul a {
  padding: 0.5rem 0.5rem;
}

.layout-sidebar {
  height: calc(100vh - 5.2rem);
}

.p-unfluid .p-button {
  width: unset;
}